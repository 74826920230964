import React from 'react';
import { useGLTF } from '@react-three/drei';

const FireInTheSky = () => {
  const fireInTheSky = useGLTF('./fire_in_the_sky/scene.gltf');

  return (
    <mesh>
      <hemisphereLight />
      <spotLight position={[-20, 50, 10]} angle={0.1} />
      <pointLight intensity={1} />
      <primitive object={fireInTheSky.scene} scale={2} position={[0, -1, 0]} rotation={[0, 0, 0]} />
    </mesh>
  );
};

export default FireInTheSky;
