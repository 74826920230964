import { algoapp, chatapp, toolapp, tasktalk, findmybook, shoesstore, onlinelibrary, sudoku, calculator } from 'assets';

export const projects = [
  {
    name: 'Chat App',
    description: 'AI chat bot and anonymous chat platform',
    tags: [
      {
        name: 'AI',
        color: 'orange-text-gradient',
      },
      {
        name: 'chatgpt',
        color: 'orange-text-gradient',
      },
      {
        name: 'react',
        color: 'blue-text-gradient',
      },
      {
        name: 'spring-boot',
        color: 'green-text-gradient',
      },
      {
        name: 'websockets',
        color: 'green-text-gradient',
      },
      {
        name: 'tailwindcss',
        color: 'pink-text-gradient',
      },
    ],
    image: chatapp,
    source_code_link: 'https://fullstack.lightdevops.com/chat-app/',
  },
  {
    name: 'Tool App',
    description: 'AI tool for multiple use cases',
    tags: [
      {
        name: 'AI',
        color: 'orange-text-gradient',
      },
      {
        name: 'react',
        color: 'blue-text-gradient',
      },
      {
        name: 'gatsby',
        color: 'blue-text-gradient',
      },
      {
        name: 'spring-boot',
        color: 'green-text-gradient',
      },
      {
        name: 'restapi',
        color: 'green-text-gradient',
      },
      {
        name: 'tailwindcss',
        color: 'pink-text-gradient',
      },
    ],
    image: toolapp,
    source_code_link: 'https://tool.lightdevops.com/',
  },
  {
    name: 'Algo App',
    description: 'Algorithm demonstration',
    tags: [
      {
        name: 'algorithms',
        color: 'orange-text-gradient',
      },
      {
        name: 'react',
        color: 'blue-text-gradient',
      },
      {
        name: 'redux',
        color: 'green-text-gradient',
      },
      {
        name: 'tailwindcss',
        color: 'pink-text-gradient',
      },
    ],
    image: algoapp,
    source_code_link: 'https://algo.lightdevops.com/',
  },
  {
    name: 'Task Talk',
    description:
      'A scheduler application to manage projects, tickets, releases. The main key feature of this application is to create a task structure with information such as status, date and people involved. The second key feature is to ensure communication between the appropriate people during the lifecycle of a task.',
    tags: [
      {
        name: 'microservices',
        color: 'orange-text-gradient',
      },
      {
        name: 'react',
        color: 'blue-text-gradient',
      },
      {
        name: 'redux',
        color: 'blue-text-gradient',
      },
      {
        name: 'spring-boot',
        color: 'green-text-gradient',
      },
      {
        name: 'kafka',
        color: 'green-text-gradient',
      },
      {
        name: 'hypersql',
        color: 'green-text-gradient',
      },
      {
        name: 'tailwindcss',
        color: 'pink-text-gradient',
      },
    ],
    image: tasktalk,
    source_code_link: 'https://www.lightdevops.com/fullstack-project/task-talk/',
  },
  {
    name: 'Find My Book',
    description:
      'This is a book reservation platform from more than 60 libraries in Paris. The database is built with over 10 thousands books which are classified into over 100 themes and have nearly 1 million copies',
    tags: [
      {
        name: 'react',
        color: 'blue-text-gradient',
      },
      {
        name: 'spring-boot',
        color: 'green-text-gradient',
      },
      {
        name: 'oracle',
        color: 'green-text-gradient',
      },
      {
        name: 'tailwindcss',
        color: 'pink-text-gradient',
      },
    ],
    image: findmybook,
    source_code_link: 'https://fullstack.lightdevops.com/find-my-book/',
  },
  {
    name: 'Shoes Store',
    description:
      'An online shoes store with more than 10 thousands items. User can search and check all the details of his favorite shoes. Shoes information are based on amazon.uk',
    tags: [
      {
        name: 'angular',
        color: 'blue-text-gradient',
      },
      {
        name: 'spring-boot',
        color: 'green-text-gradient',
      },
      {
        name: 'mongodb',
        color: 'green-text-gradient',
      },
      {
        name: 'stripe',
        color: 'green-text-gradient',
      },
      {
        name: 'auth0',
        color: 'green-text-gradient',
      },
      {
        name: 'tailwindcss',
        color: 'pink-text-gradient',
      },
    ],
    image: shoesstore,
    source_code_link: 'https://fullstack.lightdevops.com/shoes-store/',
  },
  {
    name: 'Online Library',
    description: 'An online library where we can search and borrow books. Each user has their own page to manage borrowed books and history.',
    tags: [
      {
        name: 'jquery',
        color: 'blue-text-gradient',
      },
      {
        name: 'handlebars',
        color: 'blue-text-gradient',
      },
      {
        name: 'nodejs',
        color: 'green-text-gradient',
      },
      {
        name: 'expressjs',
        color: 'green-text-gradient',
      },
      {
        name: 'mongodb',
        color: 'green-text-gradient',
      },

      {
        name: 'tailwindcss',
        color: 'pink-text-gradient',
      },
    ],
    image: onlinelibrary,
    source_code_link: 'https://fullstack.lightdevops.com/shoes-store/',
  },
  {
    name: 'Sudoku',
    description: 'An sudoku game with 3 levels: easy, medium and hard. User can check the solution of the game.',
    tags: [
      {
        name: 'mobile',
        color: 'orange-text-gradient',
      },
      {
        name: 'android',
        color: 'orange-text-gradient',
      },
      {
        name: 'algorithms',
        color: 'orange-text-gradient',
      },
      {
        name: 'java',
        color: 'green-text-gradient',
      },
    ],
    image: sudoku,
    source_code_link: 'https://play.google.com/store/apps/details?id=com.lightdevops.sudoku',
  },
  {
    name: 'Calculator',
    description: 'An calculator app with basic and complex functions.',
    tags: [
      {
        name: 'mobile',
        color: 'orange-text-gradient',
      },
      {
        name: 'android',
        color: 'orange-text-gradient',
      },
      {
        name: 'algorithms',
        color: 'orange-text-gradient',
      },
      {
        name: 'java',
        color: 'green-text-gradient',
      },
    ],
    image: calculator,
    source_code_link: 'https://play.google.com/store/apps/details?id=com.lightdevops.calculator',
  },
];
