import React from 'react';
import { motion } from 'framer-motion';

import { styles } from 'styles';
import { staggerContainer } from 'utils/motion';

const SectionWrapper = (Component, idName) => () => {
  return (
    <motion.section
      variants={staggerContainer()}
      initial='hidden'
      whileInView='show'
      viewport={{ once: true, amount: 0.25 }}
      className={`${styles.padding} z-0 min-h-screen flex flex-col bg-primary`}>
      {/* <span className='hash-span' id={idName}>
        &nbsp;
      </span> */}

      <Component />
    </motion.section>
  );
};

export default SectionWrapper;
