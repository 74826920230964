import React from 'react';
import { useGLTF } from '@react-three/drei';
import useMediaQuery from 'hooks/useMediaQuery';

const SnailMail = () => {
  const earth = useGLTF('./snail_mail/scene.gltf');
  const isMobile = useMediaQuery();

  // return <primitive object={earth.scene} scale={0.1} position-y={0} rotation-y={0} />;

  return (
    <mesh>
      <hemisphereLight />
      <spotLight position={[-20, 0, 30]} angle={1} />
      <pointLight intensity={1} />
      <primitive object={earth.scene} scale={isMobile ? 0.02 : 0.01} position={[0, -1, 0]} rotation={[0, 3.2, 0]} />
    </mesh>
  );
};

export default SnailMail;
