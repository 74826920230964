import React from 'react';

import SectionWrapper from 'components/SectionWrapper';
import BallCanvas from './BallCanvas';
import { technologies } from './technologies';

const Tech = () => {
  return (
    <div className='flex flex-col mt-20 gap-10'>
      {technologies.map((technology, index) => (
        <div className='flex flex-row flex-wrap justify-center gap-10 items-center h-full' key={index}>
          {technology.map((tech) => (
            <div className='w-28 h-28' key={tech.name}>
              <BallCanvas icon={tech.icon} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SectionWrapper(Tech, 'tech');
