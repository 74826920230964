import React from 'react';
import { motion } from 'framer-motion';

import { styles } from 'styles';
import { fadeIn, textVariant } from 'utils/motion';
import ServiceCard from './ServiceCard';
import SectionWrapper from 'components/SectionWrapper';
import { services } from 'pages/About/services';

const About = () => {
  return (
    <div className='flex flex-col mt-20'>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}>Introduction</p>
        <h2 className={styles.sectionHeadText}>Overview.</h2>
      </motion.div>

      <motion.p variants={fadeIn('', '', 0.1, 1)} className='mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]'>
        I am a software engineer with experience in Java and JavaScript with frameworks such as React and Spring Boot. <br />I am a lover of clean code and good
        coding practices. For me, getting an app to work is only half of the job, the other half is how you do it.
      </motion.p>

      <div className='mt-20 flex flex-wrap gap-10'>
        {services.map((service, index) => (
          <ServiceCard key={service.title} index={index} {...service} />
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(About, 'about');
