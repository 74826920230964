import React from 'react';
import { motion } from 'framer-motion';

import { styles } from 'styles';
import { fadeIn, textVariant } from 'utils/motion';
import ProjectCard from './ProjectCard';
import SectionWrapper from 'components/SectionWrapper';
import { projects } from './projects';

const Works = () => {
  return (
    <div className='flex flex-col mt-20'>
      <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} `}>My work</p>
        <h2 className={`${styles.sectionHeadText}`}>Projects.</h2>
      </motion.div>

      <motion.p variants={fadeIn('', '', 0.1, 1)} className='mt-3 text-secondary text-[17px] max-w-3xl leading-[30px]'>
        Professional experiences do not speak of all my skills. My strengths also come from my personal projects. <br />
        These following projects demonstrate my ability to solve complex problems, to effectively manage several projects and above all my technical curiosity.
      </motion.p>

      <div className='mt-20 flex flex-wrap gap-7'>
        {projects.map((project, index) => (
          <ProjectCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(Works, '');
