import Navbar from 'components/Navbar';
import StarsCanvas from 'components/StarsCanvas';
import { navLinks } from 'constants/navLinks';
import About from 'pages/About';
import Contact from 'pages/Contact';
import Experience from 'pages/Experience';
import Home from 'pages/Home';
import Tech from 'pages/Tech';
import Works from 'pages/Works';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <div className='relative z-0 bg-primary'>
        <Navbar />
        <Routes>
          <Route path={navLinks.home.path} element={<Home />}></Route>
          <Route path={navLinks.about.path} element={<About />}></Route>
          <Route path={navLinks.experience.path} element={<Experience />}></Route>
          <Route path={navLinks.tech.path} element={<Tech />}></Route>
          <Route path={navLinks.work.path} element={<Works />}></Route>
          <Route path={navLinks.contact.path} element={<Contact />}></Route>
        </Routes>
        {/* <div className='relative z-0'>
          <StarsCanvas />
        </div> */}
      </div>
    </BrowserRouter>
  );
};

export default App;
