import { styles } from 'styles';
import FireInTheSkyCanvas from './FireInTheSkyCanvas';

const Home = () => {
  return (
    <section className={'relative w-full h-screen mx-auto bg-cover bg-no-repeat bg-center bg-hero-pattern'}>
      <div className={`absolute inset-0 max-w-7xl mx-auto ${styles.paddingX} flex flex-row items-start gap-5`}>
        <div className='flex z-10 mt-20'>
          <h1 className={`${styles.heroHeadText} text-white`}>
            Welcome to <span className='text-[#fcba03]'>Wonderland</span>
          </h1>
        </div>

        {/* <div className='flex flex-col justify-center items-center mt-10 rotate-45 -translate-x-[323px] translate-y-[110px] md:-translate-x-[621px] md:translate-y-[21px]'>
          <div className='w-4 h-4 md:w-6 md:h-6 rounded-full bg-[#fcba03]' />
          <div className='w-1 sm:h-[500px] h-40 fcba03-gradient' />
        </div> */}
      </div>

      <FireInTheSkyCanvas />
    </section>
  );
};

export default Home;
