import React from 'react';

const UlLi = ({ items, prefix }) => {
  if (typeof items === 'string') {
    return <li className='text-white-100 text-[14px] pl-1 tracking-wider'>{`${prefix}: ${items}`}</li>;
  }
  return (
    <>
      <li className='text-white-100 text-[14px] pl-1 tracking-wider'>{`${prefix}:`}</li>
      <ul className='mt-5 list-none ml-3 space-y-2'>
        {items.map((item, index) => (
          <li key={`point-${index}`} className='text-white-100 text-[14px] pl-1 tracking-wider break-words'>
            {`- ${item}`}
          </li>
        ))}
      </ul>
    </>
  );
};

export default UlLi;
