import {
  javascript,
  java,
  sql,
  oracle,
  postgres,
  typescript,
  reactjs,
  spring,
  hibernate,
  redux,
  mongodb,
  git,
  jira,
  mui,
  kafka,
  agile,
  rest,
  microservices,
  graphql,
} from 'assets';

export const technologies = [
  [
    {
      name: 'JavaScript',
      icon: javascript,
    },
    {
      name: 'TypeScript',
      icon: typescript,
    },
    {
      name: 'React JS',
      icon: reactjs,
    },
    {
      name: 'Redux Toolkit',
      icon: redux,
    },
    {
      name: 'Mui',
      icon: mui,
    },
  ],
  [
    {
      name: 'Rest Api',
      icon: rest,
    },
    {
      name: 'Microservices',
      icon: microservices,
    },
    {
      name: 'GraphQL',
      icon: graphql,
    },
  ],
  [
    {
      name: 'Java',
      icon: java,
    },
    {
      name: 'Sql',
      icon: sql,
    },
    {
      name: 'Spring Boot',
      icon: spring,
    },
    {
      name: 'Hibernate',
      icon: hibernate,
    },
    {
      name: 'Kafka',
      icon: kafka,
    },
  ],
  [
    {
      name: 'OracleDB',
      icon: oracle,
    },
    {
      name: 'PostgreSQL',
      icon: postgres,
    },
    {
      name: 'MongoDB',
      icon: mongodb,
    },
  ],
];
