import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import { motion } from 'framer-motion';

import 'react-vertical-timeline-component/style.min.css';

import { styles } from 'styles';
import { textVariant } from 'utils/motion';
import ExperienceCard from './ExperienceCard';
import SectionWrapper from 'components/SectionWrapper';
import { experiences } from 'pages/Experience/experiences';

const Experience = () => {
  return (
    <div className='flex flex-col mt-20'>
      <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} text-center`}>What I have done so far</p>
        <h2 className={`${styles.sectionHeadText} text-center`}>Professional experience.</h2>
      </motion.div>

      <div className='mt-20'>
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard key={`experience-${index}`} experience={experience} />
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default SectionWrapper(Experience, 'work');
