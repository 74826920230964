import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Preload } from '@react-three/drei';

import CanvasLoader from 'components/Loader';
import SnailMail from '../SnailMail';

const SnailMailCanvas = () => {
  return (
    <Canvas
      shadows
      frameloop='demand'
      dpr={[1, 2]}
      gl={{ preserveDrawingBuffer: true }}
      camera={{
        fov: 45,
        near: 0.1,
        far: 200,
        position: [-4, 3, 6],
      }}>
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls autoRotate={true} enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
        <SnailMail />

        <Preload all />
      </Suspense>
    </Canvas>
  );
};

export default SnailMailCanvas;
