import React, { Suspense } from 'react';
import Ball from '../Ball';
import CanvasLoader from 'components/Loader';
import { OrbitControls, Preload } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';

const BallCanvas = ({ icon }) => {
  return (
    <Canvas frameloop='demand' dpr={[1, 2]} gl={{ preserveDrawingBuffer: true }}>
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls enableZoom={false} />
        <Ball imgUrl={icon} />
      </Suspense>

      <Preload all />
    </Canvas>
  );
};

export default BallCanvas;
