import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import UlLi from '../UlLi';

const ExperienceCard = ({ experience }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: '#1d1836',
        color: '#fff',
      }}
      contentArrowStyle={{ borderRight: '7px solid  #232631' }}
      date={experience.date}
      iconStyle={{ background: experience.iconBg }}
      icon={
        <div className='flex justify-center items-center w-full h-full'>
          <img src={experience.icon} alt={experience.company_name} className='w-[60%] h-[60%] object-contain' />
        </div>
      }>
      <div>
        {experience.title && <h3 className='text-white text-[24px] font-bold'>{experience.title}</h3>}
        {experience.company_name && (
          <p className='text-secondary text-[16px] font-semibold' style={{ margin: 0 }}>
            {experience.company_name}
          </p>
        )}
      </div>

      <ul className='mt-5 list-disc ml-5 space-y-2'>
        {experience.description && <li className='text-white-100 text-[14px] pl-1 tracking-wider'>{`Description: ${experience.description}`}</li>}
        {experience.technologies && <UlLi items={experience.technologies} prefix='Technologies' />}
        {experience.methodologies && <UlLi items={experience.methodologies} prefix='Methodologies' />}
        {experience.responsibilities && <UlLi items={experience.responsibilities} prefix='Responsibilities' />}
      </ul>
    </VerticalTimelineElement>
  );
};

export default ExperienceCard;
