import './navbar.css';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { styles } from 'styles';
import { menu, close } from 'assets';
import { navLinks } from 'constants/navLinks';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const location = useLocation();

  return (
    <nav className={`${styles.paddingX} w-full flex items-center py-5 fixed top-0 z-20 ${location.pathname === '/' ? 'bg-transparent' : 'bg-primary'}`}>
      <div className='w-full flex justify-center items-center mx-auto'>
        <ul className='list-none hidden sm:flex flex-row gap-10'>
          {Object.values(navLinks).map((nav) => (
            <li key={nav.path} className={`nav-bar-li ${location.pathname === nav.path ? 'active' : ''} text-[18px] font-medium cursor-pointer`}>
              <Link to={nav.path}>{nav.title}</Link>
            </li>
          ))}
        </ul>

        <div className='sm:hidden flex flex-1 justify-end items-center'>
          <img src={toggle ? close : menu} alt='menu' className='w-[28px] h-[28px] object-contain' onClick={() => setToggle(!toggle)} />

          <div className={`${!toggle ? 'hidden' : 'flex'} p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}>
            <ul className='list-none flex justify-end items-start flex-1 flex-col gap-4'>
              {Object.values(navLinks).map((nav) => (
                <li
                  key={nav.path}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${location.pathname === nav.path ? 'text-white' : 'text-secondary'}`}
                  onClick={() => {
                    setToggle(!toggle);
                  }}>
                  <Link to={nav.path}>{nav.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
