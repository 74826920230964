import { mobile, backend, web } from 'assets';

export const services = [
  {
    title: 'Web Developer',
    icon: web,
  },
  {
    title: 'React Developer',
    icon: mobile,
  },
  {
    title: 'Spring Boot Developer',
    icon: backend,
  },
];
