import { viveris, ijl, gaelSystems, company } from 'assets';

export const experiences = [
  {
    title: 'Lead Dev - React',
    company_name: 'Viveris',
    icon: viveris,
    iconBg: '#383E56',
    date: 'June 2023 - Present',
    description: 'Front end development for the website https://www.cfa-afia.com/',
    technologies: 'Rest Api, JavaScript, React, Redux, Jest, Cypress, MUI, Gitlab, Jira, Agile (Scrum), PostgreSql',
    methodologies: ['Full Agile Scrum methodology with 15-day sprints', 'Sprint meetings : daily, review, planning, retrospective, UI/UX'],
    responsibilities: [
      'Ensuring team adherence to project quality standards',
      'Keeping projects on schedule to meet deadlines',
      'Training developers and overseeing their work',
      'Principal solutions for front end development',
    ],
  },
  {
    title: 'Front End Developer - React',
    company_name: 'Viveris',
    icon: viveris,
    iconBg: '#383E56',
    date: 'October 2022 - Present',
    description: 'Front end development for the website https://www.cfa-afia.com/',
    responsibilities: [
      'Develop and test new front-end features',
      'Refacto and create reusable components',
      'Optimize performance',
      'Code review, technical meeting',
    ],
  },
  {
    title: 'Back End Developer - Spring Boot',
    company_name: 'Viveris',
    icon: viveris,
    iconBg: '#383E56',
    date: 'September 2022 - Present',
    description: 'Back end development for soil and subsoil data APIs',
    technologies: 'Rest Api, Java 17, Spring Boot, Hibernate, PostgreSql, Pulsar, Gitlab, Docker',
    methodologies: 'Simple Agile Scrum methodology with Daily and Review meeting.',
    responsibilities: [
      'Define the application structure according to customer specifications',
      'Create, test, deliver and debug the application',
      'Code review, technical meetings',
      'Communicate with front-end developers and devops for testing and deployment',
    ],
  },
  {
    title: 'Full Stack Developer',
    company_name: 'Gael Systems',
    icon: gaelSystems,
    iconBg: '#383E56',
    date: 'May 2022 - July 2022',
    description: 'Project management application (Jira type)',
    technologies: [
      'Back-end: Rest Api, Java 17, Micro-services, Spring Boot, Hibernate, HyperSql, Eureka, Kafka, Node-Red',
      'Front-end: TypeScript, React, Redux, TailwindCss',
    ],
    methodologies: 'Simple Agile Scrum methodology with Daily and Review meeting.',
    responsibilities: [
      'Define the architecture of micro-services',
      'Develop back-end micro-services',
      'Configure the connection between micro-services',
      'Full responsibility for front-end development',
    ],
  },
  {
    title: 'Desktop Developer',
    company_name: 'Institut Jean Lamour',
    icon: ijl,
    iconBg: '#383E56',
    date: 'June 2017 - February 2019',
    description: 'Mechanical calculation application, desktop type',
    technologies: 'Python, Tkinter',
    responsibilities: [
      'Full responsibility from design to deployment',
      'Write articles, documentation',
      'Organize application training',
      'Participate in conferences to present the application',
    ],
  },
  {
    title: 'Mechanical Engineer',
    company_name: 'Airbbus, Vinci Energies, Actemium, MCA Group, Institut Jean Lamour, UTT Research Lab',
    icon: company,
    iconBg: '#383E56',
    date: 'April 2016 - February 2021',
  },
];
