export const navLinks = {
  home: {
    path: '/',
    title: 'Home',
  },
  about: {
    path: '/about',
    title: 'About',
  },
  experience: {
    path: '/experience',
    title: 'Experience',
  },
  tech: {
    path: '/tech',
    title: 'Technologies',
  },
  work: {
    path: '/work',
    title: 'Work',
  },
  contact: {
    path: '/contact',
    title: 'Contact',
  },
};
